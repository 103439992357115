import React, { Component } from "react";
// import { withTranslation } from "react-i18next";
import "../../style/contact.css";
import QR1 from "../../assets/client/qr1.webp";
import QR2 from "../../assets/client/qr2.png";
import QR3 from "../../assets/client/qr3.webp";


class Contact extends Component  {
    render() {
        // const { t } = this.props; 
        return (
            // Contact Section
            <section id="contact">
                <div className="contact-section" >
                    <div className="container mx-auto">

                        {/* About Head */}
                        <div className="sentence-container">
                            <div className="side-line"></div>
                            <h2>Contact US</h2>
                        </div>

                        {/* Contact Boxes */}
                        <div className="contact-box">
                            
                            {/* Box One */}
                            <div className="box-one">
                                <div className="sidebar-info_more">
                                    <div className="box-title">
                                        <p>Contact US</p>
                                        <div class="line"></div>
                                    </div>

                                    {/* Box Social */}
                                    <div className="contacts-list">
                                        {/* Social One */}
                                        <div className="contact-item">
                                            <div className="icon-box">
                                                <i className="fa-solid fa-envelope"></i>
                                            </div>
                                            <div className="contact-info">
                                                <p className="contact-title">Email</p>
                                                <a href="mailto:contact@abavantage.com" className="contact-link">contact@abavantage.com</a>
                                            </div>
                                        </div>

                                        {/* Social Two */}
                                        <div className="contact-item">
                                            <div className="icon-box">
                                                <i class="fa-solid fa-phone"></i>
                                            </div>
                                            <div className="contact-info">
                                                <p className="contact-title">Phone</p>
                                                <a href="tel:+201111197012" className="contact-link">+201111197012</a>
                                            </div>
                                        </div>

                                        <div id="icons">
                                            <div className="container">
                                                <a className="social-icon11" id="face" href="https://facebook.com/abavantage" target="_blank"><i className="fab fa-fw fa-facebook" aria-hidden="true" id="icon-i"></i></a>
                                                <a className="social-icon11" id="insta" href="https://instagram.com/abavantage" target="_blank"><i className="fa-brands fa-instagram" aria-hidden="true" id="icon-i"></i></a>
                                                <a className="social-icon11" id="linked" href="https://linkedin.com/company/abavantage" target="_blank"><i className="fab fa-fw fa-linkedin" aria-hidden="true" id="icon-i"></i></a>
                                            </div>
                                        </div> 

                                    </div>
                                </div>
                                {/* <img src="/my-logo.png" className="absolute w-10 right-10 bottom-5" alt="Ara's logo" data-v-47bbe458="" /> */}
                            </div>

                            

                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;