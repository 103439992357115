import React, {Component} from "react";
// import { withTranslation } from "react-i18next";
import '../../style/about.css';


class About extends Component {
    render() { 
        // const { t } = this.props; 
        return(
            // About Section 
            <section id="about">
                <div className='about' >
                    <div className="container">
                        <div className="row justify-content" >

                            {/* About Head */}
                            <div className="sentence-container">
                                <div className="side-line"></div>
                                <h2>About US</h2>
                            </div>

                            {/* About Body */}
                            <div className="col-lg-12 " id="about-box">
                                <p className="about-title">
                                    <p>Co-founded by Ayman Elkasaby and Bassel Riad, AB Avantage made its debut in Taiwan in 2020, 
                                        positioning itself as a forward-thinking fintech company aiming to revolutionize the global financial landscape with innovative technological solutions. 
                                        In 2023, the dynamic duo launched the Egyptian entity to continue its pursuit of its original mission. Since its beginnings, 
                                        the team has cultivated an expansive reach across various international markets through an exclusive assembly of sophisticated financial technological solutions. 
                                        As we continue our journey, we remain committed to driving excellence and innovation in the financial technology sector, resolving major challenges in this arena.
                                    </p>
                                </p>
                            </div>
                            
                        </div>
                    </div>           
                </div>

            </section>
        );
    }
}

export default About; 