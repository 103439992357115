import React, { Component } from "react";
// import { withTranslation } from "react-i18next";
import "../../style/project.css";
import Logo1 from "../../assets/client/logo1.webp";
import Logo2 from "../../assets/client/logo2.webp";
import Logo3 from "../../assets/client/logo3.webp";
import Logo4 from "../../assets/client/logo4.webp";
import Logo5 from "../../assets/client/logo5.webp";
import Logo6 from "../../assets/client/logo6.webp";
import Logo7 from "../../assets/client/logo7.webp";
import Logo8 from "../../assets/client/logo8.webp";
import Logo9 from "../../assets/client/logo9.webp";
import Logo10 from "../../assets/client/logo10.webp";


class Project extends Component {
    render() {
        // const { t } = this.props; 
		return (
		// Agents Section
        <section id="project">
            <div className="projects-section" id="project">

				{/* Agents Head */}
				<div className="sentence-container container">
                    <div className="side-line"></div>
                    <h2> Our Network</h2>
                </div>
                
				{/* Agents Body */}
                <div className="container pt-6 mx-auto" id="container">

					{/* Agents Title */}
					<p class="intro-clients__title uppercase font-bold text-sm sm:text-base mb-12 tracking-wider">
						Our Network Includes:
					</p>

					{/* Agents Cards */}
					<ul className="flex flex-wrap items-center justify-between client-list mb-16">

						<li className="w-1/3 sm:w-1/4 md:w-1/5 p-4 load-hidden">
							<img src={Logo1} alt="Agents-Logo"/>
						</li>

						<li className="w-1/3 sm:w-1/4 md:w-1/5 p-4 load-hidden">
							<img src={Logo2} alt="Agents-Logo"/>
						</li>

						<li className="w-1/3 sm:w-1/4 md:w-1/5 p-4 load-hidden">
							<img src={Logo3} alt="Agents-Logo"/>
						</li>

						<li className="w-1/3 sm:w-1/4 md:w-1/5 p-4 load-hidden">
							<img src={Logo4} alt="Agents-Logo"/>
						</li>

						<li className="w-1/3 sm:w-1/4 md:w-1/5 p-4 load-hidden">
							<img src={Logo5} alt="Agents-Logo"/>
						</li>

						<li className="w-1/3 sm:w-1/4 md:w-1/5 p-4 load-hidden">
							<img src={Logo6} alt="Agents-Logo"/>
						</li>

						<li className="w-1/3 sm:w-1/4 md:w-1/5 p-4 load-hidden">
							<img src={Logo7} alt="Agents-Logo"/>
						</li>

						<li className="w-1/3 sm:w-1/4 md:w-1/5 p-4 load-hidden">
							<img src={Logo8} alt="Agents-Logo"/>
						</li>

						<li className="w-1/3 sm:w-1/4 md:w-1/5 p-4 load-hidden">
							<img src={Logo9} alt="Agents-Logo"/>
						</li>

						<li className="w-1/3 sm:w-1/4 md:w-1/5 p-4 load-hidden">
							<img src={Logo10} alt="Agents-Logo"/>
						</li>

					</ul>
					
                </div>
            </div>
        </section>    
    );
    }
}

export default Project;
