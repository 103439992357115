// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import zh from './zh.json';
import "./style/style.css";


i18n.use(initReactI18next).init({

    resources: {
            en: { translation: en },
            zh: { translation: zh }
        },

    lng: 'zh',
    fallbackLng: 'zh',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
