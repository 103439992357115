import React from "react";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import "./App.css";
import Navbar from "./components/navbar/navbar";
import Home from "./components/home/home";
import Team from "./components/team/team";
import Service from "./components/service/service";
import Project from "./components/project/project";
import Contact from "./components/contact/contact";
import Office from "./components/office/office";
import About from "./components/about/about";
import Footer from "./components/footer/footer";

function App() {
  return (
      <div className="App">
        {/* Navbar Section */}
        <I18nextProvider i18n={i18n}>
          <Navbar />
        </I18nextProvider>
        {/* Home Section */}
        <Home />
        {/* About Section */}
        <About />
        {/* Services Section */}
        <Service />
        {/* Team Section */}
        <Team />
        {/* Offices Section */}
        <Office />
        {/* Clients Section */}
        <Project />
        {/* Contact Section */}
        <Contact />
        {/* Footer Section */}
        <Footer />
      </div>
  );
}

export default App;

