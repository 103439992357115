import React, { Component } from "react";
// import { withTranslation } from "react-i18next";

import "../../style/service.css";

class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFullText1: false,
            showFullText2: false,
            showFullText3: false,
        };
    }

    toggleText1 = () => {
        this.setState((prevState) => ({
            showFullText1: !prevState.showFullText1,
        }));
    };

    toggleText2 = () => {
        this.setState((prevState) => ({
            showFullText2: !prevState.showFullText2,
        }));
    };

    toggleText3 = () => {
        this.setState((prevState) => ({
            showFullText3: !prevState.showFullText3,
        }));
    };

    render() {
        const { showFullText1, showFullText2, showFullText3 } = this.state;
        // const { t } = this.props;

        return (
            <section id="service">
                <div className="services-section">
                    <div className="container mx-auto">
                        {/* Service Head */}
                        <div className="sentence-container">
                            <div className="side-line"></div>
                            <h2>Services</h2>
                        </div>

                        {/* Services Box Section */}
                        <div className="services-box">
                            {/* Card One */}
                            <div className="services-card">
                                <div className="overflow">
                                    <p className="services-title text-center">
                                        Global Markets
                                    </p>
                                    <div>
                                        {showFullText1 ? (
                                            <p className="services-desc">
                                                At AB Avantage, our expertise in advanced algorithmic trading extends to a diverse range of financial instruments, including bonds, swaps, carbon trading, and various capital market assets. Utilizing AI and machine learning, we analyze and interpret vast market data across these segments, identifying unique trading opportunities. This comprehensive approach provides our clients with a significant competitive edge in global markets, spanning traditional financial instruments to emerging sectors like environmental commodities.
                                                <br /> <br />
                                                In addition to our robust trading solutions, AB Avantage is a key player in the financial services sector, offering exceptional international payment solutions. Our services are designed for seamless cross-border transactions, accommodating multiple currencies to minimize costs in international trade. We also integrate local switches, ensuring secure and prompt domestic transactions, thus providing an unmatched holistic financial experience.
                                                <br /> <br />
                                                Our commitment to innovation, technology, and a broad spectrum of financial services positions us at the forefront of international finance and trading. We continually adapt to meet the evolving needs of our clients, staying ahead in a dynamic global financial landscape.
                                                
                                            </p>
                                        ) : (
                                            <p className="services-desc"></p>
                                        )}

                                        <div className="btn-box">
                                            <button onClick={this.toggleText1} className="btn">
                                                {showFullText1 ? 'Show Less' : 'Show More'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Card Two */}
                            <div className="services-card">
                                <div className="overflow">
                                    <p className="services-title text-center">
                                        Investment Banking
                                    </p>
                                    <div>
                                        {showFullText3 ? (
                                            <p className="services-desc">
                                                AB Avantage, with its extensive network of over 500 venture capitalists and 6,000 startups, is renowned for effectively facilitating strategic connections across various investment stages. The company also provides comprehensive services in regulatory compliance, paperwork, and due diligence, supporting these partnerships through legal partners globally. It's set to further innovate in this domain with the launch of its digital platform, <a href="checktofund.com" target="_blank">checktofund.com</a>, in Q1 2024, aimed at revolutionizing the business matchmaking process between investors and startups.
                                                <br /> <br />
                                                In corporate finance, AB Avantage offers a wide range of services, focusing on Mergers and Acquisitions (M&A) and Initial Public Offerings (IPOs). The company's team, comprised of diverse finance experts, delivers customized strategic solutions and guidance to businesses seeking growth, leveraging their extensive experience in investment banking, corporate finance, and financial analysis.
                                                <br /> <br />
                                                Additionally, AB Avantage provides vital risk compliance and regulatory services. Through detailed risk assessments and tailored solutions, the company ensures that businesses adhere to industry standards and regulatory requirements. Their all-encompassing services, which include financial reporting and data protection compliance, enable clients to confidently manage complex regulatory landscapes.
                                                <br /> <br />
                                                This multifaceted approach positions AB Avantage as a pivotal partner in corporate financing, compliance, and as an innovative facilitator in the investment landscape.
                                            </p>
                                        ) : (
                                            <p className="services-desc"></p>
                                        )}

                                        <div className="btn-box">
                                            <button onClick={this.toggleText3} className="btn">
                                                {showFullText3 ? 'Show Less' : 'Show More'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Card Three */}
                            <div className="services-card">
                                <div className="overflow">
                                    <p className="services-title text-center">
                                        Financial Inclusion
                                    </p>
                                    <div>
                                        {showFullText2 ? (
                                            <p className="services-desc">
                                                AB Avantage is actively involved in enhancing financial inclusion across emerging markets, focusing on integrating the parallel economy of retail into the formal financial sector. Their efforts are geared towards developing and encouraging the use of digital payment solutions that are accessible in the informal economy, particularly benefiting the public in addition to small and medium-sized enterprises. These initiatives help bridge the gap between informal and formal economic sectors, improving overall market efficiency and accessibility.
                                                <br /> <br />
                                                In the realm of financial literacy, AB Avantage conducts educational programs aimed at individuals in emerging markets, teaching basic financial concepts and the importance of technological incorporation within the financial world. AB Avantage collaborates with local organizations to tailor their programs, ensuring cultural relevance. Additionally, the company provides online resources through it’s Fintech Academy, launching in Q1 2024, including webinars and interactive workshops, to broaden the reach of financial education and hands-on fintech exposure.
                                            </p>
                                        ) : (
                                            <p className="services-desc"></p>
                                        )}

                                        <div className="btn-box">
                                            <button onClick={this.toggleText2} className="btn">
                                                {showFullText2 ? 'Show Less' : 'Show More'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Service;
